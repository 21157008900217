var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welcome-header"},[_c('div',{staticClass:"welcome-header-content",class:{ 'flex-column-reverse': _vm.$vuetify.breakpoint.mdAndDown }},[_c('div',{staticClass:"welcome-header-main"},[_c('div',{staticClass:"welcome-header-title d-flex flex-column flex-sm-row"},[_c('span',{staticClass:"slider-title-first"},[_vm._v(_vm._s(_vm.$t('welcome.FindExperiencesTitleShort')))]),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.$t('welcome.FindExperiencesNew')))]),_c('div',{staticClass:"slider-title pl-2"},_vm._l((_vm.cities),function(city){return _c('span',{key:city.title,staticClass:"slider-title-text"},[_vm._v(" "+_vm._s(city.title)+" ")])}),0)])]),_c('div',{staticClass:"welcome-header-subtitle size16-weight400"},[_vm._v(" "+_vm._s(_vm.$t('welcome.FindExperiencesSubtitle'))+" ")]),_c('SearchComponent',{ref:"SearchComponent"})],1),_c('div',{staticClass:"welcome-header-features"},[_c('div',{staticClass:"welcome-header-language"},[_c('LanguageSwitcher',{attrs:{"languages":_vm.airlineLanguages,"isWelcomePage":""}})],1),_c('div',{staticClass:"welcome-header-event-wrapper size12-weight400"},[_c('div',{staticClass:"welcome-header-event"},_vm._l((_vm.cities),function(city){return _c('div',{key:city.event_id,staticClass:"welcome-header-link-wrapper",on:{"click":function($event){return _vm.pushWelcomeBackgroundLinkAnalytics(city)}}},[_c('router-link',{staticClass:"welcome-header-link",attrs:{"to":{
                name: 'booking-details',
                params: { id: city.event_id },
                query: { welcome: true },
              }}},[_vm._v(" "+_vm._s(_vm.$t('welcome.pictured'))+" "),_c('span',{staticClass:"welcome-header-link-name"},[_vm._v(" "+_vm._s(city.event_name)+" ")])])],1)}),0)])])]),_c('div',{staticClass:"welcome-header-hero"},_vm._l((_vm.cities),function(city){return _c('v-img',{key:city.name,staticClass:"welcome-header-hero-slide",attrs:{"src":require(`@/assets/hero/${
          _vm.$vuetify.breakpoint.smAndDown ? city.preview_mob : city.preview
        }`)}})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }