<template>
  <div class="welcome-header">
    <div
      class="welcome-header-content"
      :class="{ 'flex-column-reverse': $vuetify.breakpoint.mdAndDown }"
    >
      <div class="welcome-header-main">
        <div class="welcome-header-title d-flex flex-column flex-sm-row">
          <span class="slider-title-first">{{
            $t('welcome.FindExperiencesTitleShort')
          }}</span>
          <div class="d-flex">
            <span>{{ $t('welcome.FindExperiencesNew') }}</span>
            <div class="slider-title pl-2">
              <span
                class="slider-title-text"
                v-for="city in cities"
                :key="city.title"
              >
                {{ city.title }}
              </span>
            </div>
          </div>
        </div>
        <div class="welcome-header-subtitle size16-weight400">
          {{ $t('welcome.FindExperiencesSubtitle') }}
        </div>
        <SearchComponent ref="SearchComponent" />
      </div>
      <div class="welcome-header-features">
        <div class="welcome-header-language">
          <LanguageSwitcher :languages="airlineLanguages" isWelcomePage />
        </div>
        <div class="welcome-header-event-wrapper size12-weight400">
          <div class="welcome-header-event">
            <div
              class="welcome-header-link-wrapper"
              @click="pushWelcomeBackgroundLinkAnalytics(city)"
              v-for="city in cities"
              :key="city.event_id"
            >
              <router-link
                class="welcome-header-link"
                :to="{
                  name: 'booking-details',
                  params: { id: city.event_id },
                  query: { welcome: true },
                }"
              >
                {{ $t('welcome.pictured') }}
                <span class="welcome-header-link-name">
                  {{ city.event_name }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="welcome-header-hero">
      <v-img
        class="welcome-header-hero-slide"
        :src="
          require(`@/assets/hero/${
            $vuetify.breakpoint.smAndDown ? city.preview_mob : city.preview
          }`)
        "
        v-for="city in cities"
        :key="city.name"
      />
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from '@/core/components/LanguageSwitcher';
import SearchComponent from '@/modules/welcome/features/WelcomeHooksCta/SearchComponent';
import ConfigsMixins from '@/core/mixins/ConfigsMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';

export default {
  name: 'WelcomeHeaderComponent',
  components: { SearchComponent, LanguageSwitcher },
  mixins: [ConfigsMixins, GoogleTagManagerMixins],
  data() {
    return {
      cities: [
        {
          title: this.$t('welcome.orlando'),
          preview: 'orlando.jpg',
          preview_mob: 'orlando_mobile.jpg',
          event_id: 'ite_hMVh9VhhFV9hhFk1n_',
          event_name: this.$t('welcome.oldTownKissimmeeAttractions'),
        },
        {
          title: this.$t('welcome.miami'),
          preview: 'miami.jpg',
          preview_mob: 'miami_mobile.jpg',
          event_id: 'ite_hZVh9VhhFVvknMzmF_',
          event_name: this.$t('welcome.zooMiamiTicketWithOptionalUpgrade'),
        },
        {
          title: this.$t('welcome.newYork'),
          preview: 'new-york.jpg',
          preview_mob: 'new-york_mobile.jpg',
          event_id: 'ite_hMVh9VhhFV1ZM191_',
          event_name: this.$t('welcome.empireStateBuildingDayAndNightEntry'),
        },
        {
          title: this.$t('welcome.bogota'),
          preview: 'bogota.jpg',
          preview_mob: 'bogota_mobile.jpg',
          event_id: 'ite_hZVh9VhhFVvvM1TlxL_',
          event_name: this.$t(
            'welcome.hikeLaChorreraAndElChiflonMightyWaterfalls'
          ),
        },
        {
          title: this.$t('welcome.barcelona'),
          preview: 'barcelona.jpg',
          preview_mob: 'barcelona_mobile.jpg',
          event_id: 'ite_hZVh9VhhFV9MF9nm1_',
          event_name: this.$t('welcome.footballClubBarcelonaPrivateTour'),
        },
        {
          title: this.$t('welcome.medelln'),
          preview: 'medellin.jpg',
          preview_mob: 'medellin_mobile.jpg',
          event_id: 'ite_hZVh9VhhFV9nZ1hnm9_',
          event_name: this.$t('welcome.rockClimbingInTheGuatapeStone'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  &-header {
    height: 380px;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 48px;
    position: relative;
    display: flex;

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-grow: 1;
      padding: 57px 62px;
      position: relative;
      z-index: 2;
      @media (max-width: map-get($grid-breakpoints, lg)) {
        padding: 32px;
      }
      @media (max-width: map-get($grid-breakpoints, md)) {
        padding: 12px 16px;
      }
    }

    &-main {
      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 100%;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &-title {
      color: #fff;
      white-space: nowrap;
      @include font-size(20, 150, 500);
      margin-bottom: 9px;

      @media (min-width: map-get($grid-breakpoints, md)) {
        @include font-size(24, 187.5, 500);
        margin-bottom: 0;
      }
      @media (min-width: map-get($grid-breakpoints, lg)) {
        @include font-size(30, 150, 500);
      }
    }

    &-subtitle {
      color: #fff;
      margin-bottom: 14px;
      max-width: 411px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        @include font-size(14, 150);
      }
    }

    &-hero {
      position: absolute;
      width: 100%;
      height: 100%;
      &-slide {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        z-index: 0;
        animation: hero-slide 36s linear infinite 0s;
        &:nth-of-type(1) {
          animation-delay: -2s;
        }
        &:nth-of-type(2) {
          animation-delay: 4s;
        }
        &:nth-of-type(3) {
          animation-delay: 10s;
        }
        &:nth-of-type(4) {
          animation-delay: 16s;
        }
        &:nth-of-type(5) {
          animation-delay: 22s;
        }
        &:nth-of-type(6) {
          animation-delay: 28s;
        }
      }
    }

    &-features {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      height: 100%;
    }

    &-language {
      max-width: 76px;
    }

    &-event {
      &-wrapper {
        display: flex;
        @media (max-width: map-get($grid-breakpoints, md)) {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &-event {
      color: #fff;
      height: 15px;
      padding-left: 15px;
      overflow: hidden;
    }

    &-link {
      color: inherit;
      text-decoration: none;
      &-wrapper {
        height: 15px;
        display: flex;
        justify-content: flex-end;
        &:nth-of-type(1) {
          animation: link-slide 36s ease-out infinite;
          animation-direction: reverse;
        }
      }
      &-name {
        padding-left: 15px;
        text-decoration: underline;
      }
    }
  }
}

.slider-title {
  height: 60px;
  padding-left: 15px;
  overflow: hidden;
  @media (max-width: map-get($grid-breakpoints, md)) {
    height: 30px;
  }

  &-first {
    padding-right: 10px;
  }

  &-text {
    display: block;
    text-decoration: underline;
    height: 50px;
    margin-bottom: 50px;
    padding: 1px 0px;
    text-align: left;
    box-sizing: border-box;

    &:nth-of-type(1) {
      animation: title-slide 36s ease-out infinite;
      animation-direction: reverse;
    }
  }
}

@keyframes title-slide {
  0% {
    margin-top: -600px;
  }
  2% {
    margin-top: -500px;
  }
  16.6% {
    margin-top: -500px;
  }
  18.6% {
    margin-top: -400px;
  }
  33.2% {
    margin-top: -400px;
  }
  35.2% {
    margin-top: -300px;
  }
  49.8% {
    margin-top: -300px;
  }
  51.8% {
    margin-top: -200px;
  }
  66.4% {
    margin-top: -200px;
  }
  68.4% {
    margin-top: -100px;
  }
  83% {
    margin-top: -100px;
  }
  85% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes link-slide {
  0% {
    margin-top: -90px;
  }
  1% {
    margin-top: -75px;
  }
  16.6% {
    margin-top: -75px;
  }
  17.6% {
    margin-top: -60px;
  }
  33.2% {
    margin-top: -60px;
  }
  34.2% {
    margin-top: -45px;
  }
  49.8% {
    margin-top: -45px;
  }
  50.8% {
    margin-top: -30px;
  }
  66.4% {
    margin-top: -30px;
  }
  67.4% {
    margin-top: -15px;
  }
  83% {
    margin-top: -15px;
  }
  84% {
    margin-top: 0px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes hero-slide {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  5% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>
