<template>
  <div class="welcome-page">
    <WelcomeHeaderComponent :hooks="hooks" />
    <div class="welcome-page-cards-wrapper">
      <WelcomeCitiesCards
        :title="$t('welcome.exploreDestinationsTitle')"
        :items="cities"
      />
    </div>

    <div class="welcome-page-trust-bar">
      <div class="welcome-page-trust-bar-item-wrapper">
        <div class="welcome-page-trust-bar-item">
          <div class="welcome-page-trust-bar-icon-wrapper">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.736 9.58009L11.264 7.75409C10.6627 7.55381 10.0224 7.49929 9.39582 7.59502C8.76928 7.69076 8.17446 7.93401 7.66038 8.30472C7.1463 8.67544 6.72767 9.16302 6.43899 9.72726C6.15031 10.2915 5.99985 10.9163 6 11.5501V35.1161C5.9998 35.956 6.26396 36.7746 6.75503 37.4559C7.2461 38.1372 7.93918 38.6467 8.736 38.9121L16.736 41.5781C17.5565 41.8514 18.4435 41.8514 19.264 41.5781L28.736 38.4221C29.5565 38.1488 30.4435 38.1488 31.264 38.4221L36.736 40.2461C37.3375 40.4464 37.978 40.5009 38.6047 40.4051C39.2314 40.3092 39.8263 40.0658 40.3404 39.6949C40.8546 39.3239 41.2732 38.8361 41.5617 38.2716C41.8502 37.7071 42.0005 37.0821 42 36.4481V12.8841C42.0003 12.0445 41.7365 11.226 41.2458 10.5447C40.7551 9.86345 40.0624 9.35383 39.266 9.08809L31.266 6.42209C30.4449 6.14836 29.5571 6.14836 28.736 6.42209L19.262 9.57809C18.4415 9.85138 17.5545 9.85138 16.734 9.57809L16.736 9.58009Z"
                stroke="var(--v-secondary-base)"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 10V42"
                stroke="var(--v-secondary-base)"
                stroke-width="4"
                stroke-linejoin="round"
              />
              <path
                d="M30 6V38"
                stroke="var(--v-secondary-base)"
                stroke-width="4"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h2 class="welcome-page-trust-bar-title findSpecial">
            {{ $t('welcome.findSpecialExperiences') }}
          </h2>
          <div class="welcome-page-trust-bar-description size14-weight400">
            {{ $t('welcome.findSpecialExperiencesDescription') }}
          </div>
        </div>
      </div>
      <div class="welcome-page-trust-bar-item-wrapper">
        <div class="welcome-page-trust-bar-item">
          <div class="welcome-page-trust-bar-icon-wrapper">
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M31.25 10.417V14.5837V10.417ZM31.25 22.917V27.0837V22.917ZM31.25 35.417V39.5837V35.417ZM10.4167 10.417C9.3116 10.417 8.25179 10.856 7.47039 11.6374C6.68899 12.4188 6.25 13.4786 6.25 14.5837V20.8337C7.35507 20.8337 8.41488 21.2726 9.19628 22.054C9.97768 22.8354 10.4167 23.8953 10.4167 25.0003C10.4167 26.1054 9.97768 27.1652 9.19628 27.9466C8.41488 28.728 7.35507 29.167 6.25 29.167V35.417C6.25 36.5221 6.68899 37.5819 7.47039 38.3633C8.25179 39.1447 9.3116 39.5837 10.4167 39.5837H39.5833C40.6884 39.5837 41.7482 39.1447 42.5296 38.3633C43.311 37.5819 43.75 36.5221 43.75 35.417V29.167C42.6449 29.167 41.5851 28.728 40.8037 27.9466C40.0223 27.1652 39.5833 26.1054 39.5833 25.0003C39.5833 23.8953 40.0223 22.8354 40.8037 22.054C41.5851 21.2726 42.6449 20.8337 43.75 20.8337V14.5837C43.75 13.4786 43.311 12.4188 42.5296 11.6374C41.7482 10.856 40.6884 10.417 39.5833 10.417H10.4167Z"
                stroke="var(--v-secondary-base)"
                stroke-width="4.16667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <h2 class="welcome-page-trust-bar-title size20-weight400">
            {{ $t('welcome.freeCancellations') }}
          </h2>
          <div class="welcome-page-trust-bar-description size14-weight400">
            {{ $t('welcome.freeCancellationsDescription') }}
          </div>
        </div>
      </div>
      <div class="welcome-page-trust-bar-item-wrapper">
        <div class="welcome-page-trust-bar-item">
          <div class="welcome-page-trust-bar-icon-wrapper">
            <svg
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.4167 10.0833C19.4334 10.0833 21.0834 8.43333 21.0834 6.41667C21.0834 4.4 19.4334 2.75 17.4167 2.75C15.4001 2.75 13.7501 4.4 13.7501 6.41667C13.7501 8.43333 15.4001 10.0833 17.4167 10.0833ZM10.5417 16.3167L5.50008 42.1667H9.35008L12.5584 27.5L16.5001 31.1667V42.1667H20.1667V28.325L16.4084 24.5667L17.5084 19.0667C19.8917 22 23.4667 23.8333 27.5001 23.8333V20.1667C24.1084 20.1667 21.1751 18.3333 19.5251 15.675L17.7834 12.7417C17.1417 11.6417 15.9501 11 14.6667 11C14.2084 11 13.7501 11.0917 13.2917 11.275L3.66675 15.2167V23.8333H7.33341V17.6917L10.5417 16.3167ZM23.8334 3.66667V16.5H30.7084V42.1667H33.4584V16.5H40.3334V3.66667H23.8334ZM33.0184 14.6667V11.4583H26.5834V8.70833H33.0184V5.5L37.5834 10.0833L33.0184 14.6667Z"
                fill="var(--v-secondary-base)"
              />
            </svg>
          </div>
          <h2 class="welcome-page-trust-bar-title size20-weight400">
            {{ $t('welcome.skipTheLine') }}
          </h2>
          <div class="welcome-page-trust-bar-description size14-weight400">
            {{ $t('welcome.skipTheLineDescription') }}
          </div>
        </div>
      </div>
    </div>

    <div class="welcome-page-cards-wrapper">
      <WelcomeExperiencesCards
        :title="
          $t('welcome.bestOfOrlandoTitle', { city: cities.orlando.title })
        "
        :items="cities.orlando.experiences"
        :query="cities.orlando.query"
        :city="cities.orlando.title"
      />
    </div>
    <div class="welcome-page-cards-wrapper">
      <WelcomeExperiencesCards
        :title="
          $t('welcome.topRatedInMiamiTitle', { city: cities.miami.title })
        "
        :items="cities.miami.experiences"
        :query="cities.miami.query"
        :city="cities.miami.title"
      />
    </div>
    <WelcomeCard
      :title="$t('welcome.mozio_title')"
      :description="$t('welcome.mozio_description')"
      :cta-text="$t('welcome.mozio_book')"
      cta-path="https://avianca.mozio.com/es/?utm_campaign=landhotelsandcar"
      image="/img/avianca/mozio.jpg"
    />
    <div class="welcome-page-cards-wrapper">
      <WelcomeExperiencesCards
        :title="
          $t('welcome.exploreNewYorkTitle', { city: cities.newYork.title })
        "
        :items="cities.newYork.experiences"
        :query="cities.newYork.query"
        :city="cities.newYork.title"
      />
    </div>
    <div class="welcome-page-cards-wrapper">
      <WelcomeExperiencesCards
        :title="
          $t('welcome.uniqueExperiencesInBogotaTitle', {
            city: cities.bogota.title,
          })
        "
        :items="cities.bogota.experiences"
        :query="cities.bogota.query"
        :city="cities.bogota.title"
      />
    </div>
    <WelcomeCard
      :title="$t('welcome.booking_title')"
      :description="$t('welcome.booking_description')"
      :cta-text="$t('welcome.booking_book')"
      cta-path="https://sp.booking.com/index.html?aid=2101683&label=pagedesthotel-link-2101683-click"
      image="/img/avianca/booking.jpg"
      image-first
    />
    <div class="welcome-page-cards-wrapper">
      <WelcomeExperiencesCards
        :title="
          $t('welcome.bestOfBarcelonaTitle', { city: cities.barcelona.title })
        "
        :items="cities.barcelona.experiences"
        :query="cities.barcelona.query"
        :city="cities.barcelona.title"
      />
    </div>
    <div class="welcome-page-cards-wrapper">
      <WelcomeExperiencesCards
        :title="
          $t('welcome.medellinAwaitsTitle', { city: cities.medellin.title })
        "
        :items="cities.medellin.experiences"
        :query="cities.medellin.query"
        :city="cities.medellin.title"
      />
    </div>
    <WelcomeCard
      :title="$t('welcome.rental_title')"
      :description="$t('welcome.rental_description')"
      :cta-text="$t('welcome.rental_book')"
      cta-path="https://www.rentalcars.com/?affiliateCode=avianca695&adplat=cardlandingpage"
      image="/img/avianca/car-rental.jpg"
    />
  </div>
</template>

<script>
import WelcomeHeaderComponent from './WelcomeHeaderComponent.vue';
import FlightRedirectMixins from '@/modules/flights/mixins/FlightRedirectMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import WelcomeCitiesCards from '@/modules/welcome/features/WelcomeHooksCta/WelcomeCitiesCards';
import WelcomeExperiencesCards from '@/modules/welcome/features/WelcomeHooksCta/WelcomeExperiencesCards';
import WelcomeCard from '@/modules/welcome/features/WelcomeHooksCta/WelcomeCard';

export default {
  name: 'WelcomeHomeComponent',
  components: {
    WelcomeCard,
    WelcomeHeaderComponent,
    WelcomeCitiesCards,
    WelcomeExperiencesCards,
  },
  mixins: [FlightRedirectMixins, GoogleTagManagerMixins],

  data() {
    return {
      addFlightMode: false,
      cities: {
        orlando: {
          title: this.$t('welcome.orlando'),
          description: '300+',
          preview: '/img/avianca/cities/orlando.jpg',
          query: { city: 'Orlando', lng: -81.379, lat: 28.5421 },
          experiences: [
            {
              title: this.$t('welcome.seaLifeOrlandoAquariumAdmissionTicket'),
              description: '$32',
              preview: '/img/avianca/experiences/orlando/sea-life.jpg',
              id: 'ite_hkVh9VhhFV9vh9z9_',
            },
            {
              title: this.$t('welcome.oldTownKissimmeeAttractions'),
              description: '$16',
              preview: '/img/avianca/experiences/orlando/old-town.jpg',
              id: 'ite_hMVh9VhhFV9hhFk1n_',
            },
            {
              title: this.$t('welcome.crayolaExperienceOrlandoTicket'),
              description: '$28',
              preview: '/img/avianca/experiences/orlando/crayola.jpg',
              id: 'ite_hZVh9VhhFVz1kFmF_',
            },
            {
              title: this.$t('welcome.museumOfIllusionsOrlando'),
              description: '$27',
              preview: '/img/avianca/experiences/orlando/illusions.jpg',
              id: 'ite_hMVh9VhhFV9h9hZh1_',
            },
            {
              title: this.$t('welcome.madameTussaudsOrlando'),
              description: '$32',
              preview: '/img/avianca/experiences/orlando/madame-tussauds.jpg',
              id: 'ite_hMVh9VhhFV1ZzFhh_',
            },
            {
              title: this.$t(
                'welcome.gatorlandOrlandoGeneralAdmissionFastTrack'
              ),
              description: '$25',
              preview: '/img/avianca/experiences/orlando/admission.jpg',
              id: 'ite_hMVh9VhhFV1Zz1n1_',
            },
          ],
        },
        miami: {
          title: this.$t('welcome.miami'),
          description: '450+',
          preview: '/img/avianca/cities/miami.jpg',
          query: { city: 'Miami', lng: -80.1937, lat: 25.7743 },
          experiences: [
            {
              title: this.$t('welcome.parasailingWithMiamiWatersports'),
              description: '$85',
              preview: '/img/avianca/experiences/miami/parasailling.jpg',
              id: 'ite_hZVh9VhhFVvvhkm9h_',
            },
            {
              title: this.$t('welcome.zooMiamiTicketWithOptionalUpgrade'),
              description: '$25',
              preview: '/img/avianca/experiences/miami/zoo.jpg',
              id: 'ite_hZVh9VhhFVvknMzmF_',
            },
            {
              title: this.$t('welcome.miamiBeachSouthPointParkTour'),
              description: '$40',
              preview: '/img/avianca/experiences/miami/beach.jpg',
              id: 'ite_hZVh9VhhFV9nk9zvm9F_',
            },
            {
              title: this.$t(
                'welcome.theOfficialArtDecoWalkingTourByTheMiamiDesign'
              ),
              description: '$35',
              preview: '/img/avianca/experiences/miami/art.jpg',
              id: 'ite_hZVh9VhhFV9hnvZnmF_',
            },
            {
              title: this.$t(
                'welcome.sightseeingHurricaneSpeedboatTourOfMiami'
              ),
              description: '$41',
              preview: '/img/avianca/experiences/miami/sightseeing.jpg',
              id: 'ite_hZVh9VhhFVkvnkMmF_',
            },
            {
              title: this.$t(
                'welcome.evergladesAdventureDayTripFromFtLauderdale'
              ),
              description: '$199',
              preview: '/img/avianca/experiences/miami/everglades.jpg',
              id: 'ite_hZVh9VhhFVvz1naWIaUC_',
            },
          ],
        },
        newYork: {
          title: this.$t('welcome.newYork'),
          description: '1300+',
          preview: '/img/avianca/cities/new-york.jpg',
          query: { city: 'New York City', lng: -73.9866, lat: 40.7306 },
          experiences: [
            {
              title: this.$t('welcome.newYorkCitySightseeingCruise'),
              description: '$25',
              preview: '/img/avianca/experiences/new-york/sightseeing.jpg',
              id: 'ite_hZVh9VhhFV9vhn9mFFF_',
            },
            {
              title: this.$t('welcome.manhattanAndBrooklynBridgeBikeRental'),
              description: '$9',
              preview: '/img/avianca/experiences/new-york/manhattan.jpg',
              id: 'ite_hZVh9VhhFVvvvZQLeLINC_',
            },
            {
              title: this.$t('welcome.highLineAndChelseaSmallGroupTour'),
              description: '$35',
              preview: '/img/avianca/experiences/new-york/high-line.jpg',
              id: 'ite_hZVh9VhhFVvFvhlEbl_',
            },
            {
              title: this.$t('welcome.empireStateBuildingDayAndNightEntry'),
              description: '$67',
              preview:
                '/img/avianca/experiences/new-york/empire-state-building.jpg',
              id: 'ite_hMVh9VhhFV1ZM191_',
            },
            {
              title: this.$t('welcome.tastesOfTheLowerEastSide'),
              description: '$40',
              preview: '/img/avianca/experiences/new-york/lower-east.jpg',
              id: 'ite_hZVh9VhhFVk9hhkmk9_',
            },
            {
              title: this.$t('welcome.radioCityMusicHallStageDoorTour'),
              description: '$31',
              preview: '/img/avianca/experiences/new-york/radio-city.jpg',
              id: 'ite_hZVh9VhhFVZzMnFmM_',
            },
          ],
        },
        bogota: {
          title: this.$t('welcome.bogota'),
          description: '650+',
          preview: '/img/avianca/cities/bogota.jpg',
          query: { city: 'Bogotá', lng: -74.08083, lat: 4.59889 },
          experiences: [
            {
              title: this.$t(
                'welcome.zipaquirasUndergroundSaltCathedralHalfDayTrip'
              ),
              description: '$119',
              preview: '/img/avianca/experiences/bogota/Image.jpg',
              id: 'ite_hZVh9VhhFVvvM17Ema_',
            },
            {
              title: this.$t(
                'welcome.hikeLaChorreraAndElChiflonMightyWaterfalls'
              ),
              description: '$99',
              preview: '/img/avianca/experiences/bogota/Image-1.jpg',
              id: 'ite_hZVh9VhhFVvvM1TlxL_',
            },
            {
              title: this.$t(
                'welcome.laCandelariaOptionalMonserrateAndGoldMuseumTour'
              ),
              description: '$58',
              preview: '/img/avianca/experiences/bogota/Image-2.jpg',
              id: 'ite_hZVh9VhhFVv1knnm9v_',
            },
            {
              title: this.$t('welcome.bogotaFoodTour'),
              description: '$92',
              preview: '/img/avianca/experiences/bogota/Image-3.jpg',
              id: 'ite_hZVh9VhhFVFF9hhzmFv_',
            },
            {
              title: this.$t('welcome.bogotaGastronomicWalkingTour'),
              description: '$66',
              preview: '/img/avianca/experiences/bogota/Image-4.jpg',
              id: 'ite_hZVh9VhhFVvvM12xxW_',
            },
            {
              title: this.$t('welcome.andesMountainsHorsebackRiding'),
              description: '$110',
              preview: '/img/avianca/experiences/bogota/Image-5.jpg',
              id: 'ite_hZVh9VhhFVMFFnFmF_',
            },
          ],
        },
        barcelona: {
          title: this.$t('welcome.barcelona'),
          description: '1500+',
          preview: '/img/avianca/cities/barselona.jpg',
          query: {
            city: this.$t('welcome.barcelona'),
            lng: 2.17694,
            lat: 41.3825,
          },
          experiences: [
            {
              title: this.$t(
                'welcome.amazing2HourExclusiveSunsetCruiseFromBarcelona'
              ),
              description: '$348',
              preview: '/img/avianca/experiences/barcelona/Image.jpg',
              id: 'ite_hZVh9VhhFVkMznZmk_',
            },
            {
              title: this.$t('welcome.footballClubBarcelonaPrivateTour'),
              description: '$146',
              preview: '/img/avianca/experiences/barcelona/Image-1.jpg',
              id: 'ite_hZVh9VhhFV9MF9nm1_',
            },
            {
              title: this.$t(
                'welcome.privateMontjuicMountainTourWithVisitToOlympicPark'
              ),
              description: '$115',
              preview: '/img/avianca/experiences/barcelona/Image-2.jpg',
              id: 'ite_hZVh9VhhFV9MF9nmM_',
            },
            {
              title: this.$t('welcome.barcelonaBikeTour'),
              description: '$30',
              preview: '/img/avianca/experiences/barcelona/Image-3.jpg',
              id: 'ite_hZVh9VhhFV99n1Mhm9_',
            },
            {
              title: this.$t('welcome.tapasAndWineTour'),
              description: '$59',
              preview: '/img/avianca/experiences/barcelona/Image-4.jpg',
              id: 'ite_hZVh9VhhFV9zzkZmz_',
            },
            {
              title: this.$t('welcome.craftBeerBarTourTastingTapas'),
              description: '$54',
              preview: '/img/avianca/experiences/barcelona/Image-5.jpg',
              id: 'ite_hZVh9VhhFVF9kkMnmZ_',
            },
          ],
        },
        medellin: {
          title: this.$t('welcome.medelln'),
          description: '750+',
          preview: '/img/avianca/cities/medellin.jpg',
          query: {
            city: this.$t('welcome.medelln'),
            lng: -75.57483,
            lat: 6.24475,
          },
          experiences: [
            {
              title: this.$t('welcome.halfdayCoffeePlantationPrivateTour'),
              description: '$89',
              preview: '/img/avianca/experiences/medellin/Image.jpg',
              id: 'ite_hZVh9VhhFVzvn99mz_',
            },
            {
              title: this.$t('welcome.StreetFoodTourEatLikeALocalInMedellin'),
              description: '$68',
              preview: '/img/avianca/experiences/medellin/Image-1.jpg',
              id: 'ite_hZVh9VhhFVZ9zFm9k_',
            },
            {
              title: this.$t('welcome.ziplineAndWaterfallTourMedellin'),
              description: '$149',
              preview: '/img/avianca/experiences/medellin/Image-2.jpg',
              id: 'ite_hZVh9VhhFVzknFMmk_',
            },
            {
              title: this.$t('welcome.tropicalBeatsDanceAfrocolombianCulture'),
              description: '$72',
              preview: '/img/avianca/experiences/medellin/Image-3.jpg',
              id: 'ite_hZVh9VhhFVZ9zFmn_',
            },
            {
              title: this.$t('welcome.rockClimbingInTheGuatapeStone'),
              description: '$52',
              preview: '/img/avianca/experiences/medellin/Image-4.jpg',
              id: 'ite_hZVh9VhhFV9nZ1hnm9_',
            },
            {
              title: this.$t('welcome.bestHorsebackAdventureFromMedellin'),
              description: '$97',
              preview: '/img/avianca/experiences/medellin/Image-5.jpg',
              id: 'ite_hZVh9VhhFV9FZ1zzm9M_',
            },
          ],
        },
      },
    };
  },

  computed: {
    hooks() {
      if (this.conciergeHooks && this.conciergeHooks.length) {
        return this.conciergeHooks;
      } else return null;
    },

    skeletonLoaderSize() {
      let pageSize = 3;
      if (this.$vuetify.breakpoint.smAndDown) {
        pageSize = 2;
      } else if (this.$vuetify.breakpoint.lgAndUp) {
        pageSize = 4;
      }
      return pageSize;
    },
  },
};
</script>

<style scoped lang="scss">
.welcome-page {
  max-width: 1128px;
  margin: 0 auto;

  &-cards {
    &-wrapper {
      margin-bottom: 24px;
    }
  }

  &-trust-bar {
    display: flex;
    width: 100%;
    margin-bottom: 48px;
    justify-content: space-between;

    @media (max-width: map-get($grid-breakpoints, md)) {
      flex-direction: column;
      margin-top: 4px;
      margin-bottom: 28px;
    }

    &-item {
      display: flex;
      align-items: flex-start;
      text-align: start;
      flex-direction: column;
      position: relative;

      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 100%;
        flex: unset;
      }

      &-wrapper {
        padding: 25px;
        margin: 12px 0;
        border-radius: 8px;
        border: 1px solid #e8e8e8;
        overflow: hidden;
        flex: 1;

        @media (min-width: map-get($grid-breakpoints, md)) {
          max-width: 30%;
        }
      }
    }

    &-icon {
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
      }
    }

    &-title {
      line-height: 150%;
      color: #000;
      margin: 24px 0 14px;

      @media (max-width: map-get($grid-breakpoints, md)) {
        margin: 8px 0 9px;
      }

      &.findSpecial {
        @include font-size(18, 150, 400);
      }
    }

    &-description {
      @include font-size(14, 150);
      color: #000;
    }
  }
}
</style>
