var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-lg my-10",style:({
    width: _vm.$vuetify.breakpoint.lgAndUp ? '100%' : null,
    border: _vm.$vuetify.breakpoint.mdAndDown ? 'none' : null,
  }),attrs:{"min-height":"100%","outlined":""}},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',{class:{ 'pa-10': _vm.$vuetify.breakpoint.lgAndUp },attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 6 : 12,"order":_vm.$vuetify.breakpoint.lgAndUp && !_vm.imageFirst ? 2 : 0}},[_c('v-img',{staticClass:"grey lighten-4 rounded-lg",class:{ 'mx-4': _vm.$vuetify.breakpoint.lgAndUp },attrs:{"alt":_vm.title,"src":_vm.image,"height":_vm.$vuetify.breakpoint.smAndDown ? 200 : 313}})],1),_c('v-col',{class:{
        'pa-16': _vm.$vuetify.breakpoint.lgAndUp,
        'pt-1': _vm.$vuetify.breakpoint.mdAndDown,
      },attrs:{"cols":_vm.$vuetify.breakpoint.lgAndUp ? 6 : 12}},[_c('v-row',[_c('v-col',{class:{ 'pt-0': _vm.$vuetify.breakpoint.mdAndDown }},[_c('h3',{staticClass:"size20-weight400"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])],1),(_vm.description)?[_c('v-row',{class:{ 'my-1': _vm.$vuetify.breakpoint.mdAndDown },attrs:{"no-gutters":""}},[_c('v-col',[_c('p',{staticClass:"mb-0 size16-weight400"},[_vm._v(" "+_vm._s(_vm.description)+" ")])])],1)]:_vm._e(),(_vm.ctaText)?[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"rounded-lg",attrs:{"height":"50","depressed":"","color":"primary","href":_vm.ctaPath,"target":"_blank","large":""}},[_vm._v(" "+_vm._s(_vm.ctaText)+" ")])],1)],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }