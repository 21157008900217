<script>
export default {
  name: 'BookingSearchMixins',
  methods: {
    searchBookingContent({
      city,
      searchText,
      latitude,
      longitude,
      skip,
      take,
      minAvailabilityDate,
      maxAvailabilityDate,
      minPrice,
      maxPrice,
      currency,
      subCategories,
      sortOption,
      faceMaskRequired,
      socialDistancingEnforced,
      intensifiedCleaning,
    }) {
      this.$store.dispatch(`discovery/search/searchBookingContent`, {
        city: city || null,
        searchText,
        latitude: city ? latitude : null,
        longitude: city ? longitude : null,
        skip,
        take,
        minAvailabilityDate,
        maxAvailabilityDate,
        minPrice,
        maxPrice,
        currency,
        subCategories,
        sortOption,
        faceMaskRequired,
        socialDistancingEnforced,
        intensifiedCleaning,
      });
    },

    loadCategories({ purchaseStrategy }) {
      this.$store.dispatch(`discovery/search/loadCategories`, {
        purchaseStrategy,
      });
    },

    performCitySearch(location) {
      this.$store.dispatch('discovery/search/performCitySearch', location);
    },

    performReverseGeocode({ lat, lng }) {
      this.$store.dispatch(`discovery/search/performReverseGeocode`, {
        lat: lat,
        lng: lng,
      });
    },
  },

  computed: {
    hasSearchResults() {
      return this.$store.getters[`discovery/search/hasSearchResults`];
    },

    searchResults() {
      return this.$store.getters[`discovery/search/searchResults`];
    },

    isSearching() {
      return !this.hasSearchResults && this.searchResults.isLoading;
    },

    isPaginatingSearch() {
      return this.hasSearchResults && this.searchResults.isLoading;
    },

    canLoadMoreResults() {
      return (
        this.hasSearchResults &&
        this.searchResults.items.length < this.searchResults.total
      );
    },

    isLoadingCategories() {
      return !this.hasCategories && this.allCategories.isLoading;
    },

    allCategories() {
      return this.$store.getters['discovery/search/allCategories'];
    },

    hasCategories() {
      return this.$store.getters[`discovery/hasCategories`];
    },

    reverseGeocode() {
      return this.$store.getters[`discovery/search/reverseGeocode`];
    },

    citySearch() {
      return this.$store.getters['discovery/search/citySearch'];
    },

    hasCitySearch() {
      return this.$store.getters['discovery/search/hasCitySearch'];
    },
  },
};
</script>
