<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters justify="space-between" align="end">
      <v-col cols="12" md="auto">
        <v-form
          @submit.prevent="onSubmit"
          action="."
          ref="form"
          class="search-box-wrapper"
        >
          <div class="search-box-location-wrapper d-flex align-center">
            <v-combobox
              class="search-box search-box-location pb-0"
              height="44px"
              dense
              flat
              solo
              outlined
              clearable
              :items="items"
              no-filter
              :value="preSelectedSearch.city"
              :loading="citySearch.isLoading"
              :search-input.sync="search"
              @change="onChange"
              @click:clear="onClear"
              color="secondary"
              prepend-inner-icon="mdi-map-marker-outline"
              append-icon=""
              :placeholder="$t('welcome.locationPlaceholder')"
              return-object
              :no-data-text="$t('product.no_cities_found')"
              label=""
              aria-labelledby="search-box"
              aria-selected="true"
              tabindex="0"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  class="item-text"
                  ripple
                  @click="getUserLocationAndRedirect"
                >
                  <v-list-item-action>
                    <v-icon color="secondary">mdi-near-me</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="item-text">
                      {{ $t('current_location') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template slot="item" slot-scope="{ item }">
                <v-list-item-action class="mr-2">
                  <v-icon color="secondary">mdi-map-marker-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="item-text">
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-combobox>
            <v-btn
              class="search-box-location-button"
              color="primary"
              type="submit"
              dense
              height="44"
              width="99"
              depressed
            >
              {{ $t('search') }}
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookingSearchMixins from '@/modules/discovery/mixins/BookingSearchMixins';
import { mapboxService } from '@/core/services/MapboxService';
import SelectedContextFlightMixins from '@/modules/flights/mixins/SelectedContextFlightMixins';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'SearchComponent',
  mixins: [
    BookingSearchMixins,
    SelectedContextFlightMixins,
    GoogleTagManagerMixins,
    HotJarMixins,
  ],
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      preSelectedSearch: {
        city: null,
        longLat: null,
      },
      selectedItemValue: {
        text: '',
        longLat: null,
        city: null,
      },
      itemFromQuery: {
        q: this.$route.query?.q || '',
        longLat: [
          this.$route.query?.lng || null,
          this.$route.query?.lat || null,
        ],
        city: this.$route.query?.city || null,
      },
      search: null,
      timer: null,
      searchQuery: '',
    };
  },

  mounted() {
    if (this.selectedContextFlight) {
      this.updateSelectedCity(this.selectedContextFlight?.destination.city);
    }
  },

  methods: {
    getUserLocationAndRedirect() {
      this.$getLocation().then(
        (coordinates) => {
          this.performReverseGeocode({
            lat: coordinates.lat,
            lng: coordinates.lng,
          });
        },
        (rejected) => {
          //Just to catch the rejection so chrome console doesn't complain
        }
      );
    },

    searchCityDebounced(cityName) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.performCitySearch(cityName);
      }, 400);
    },

    onSubmit() {
      if (this.$vuetify.breakpoint.smAndDown) this.$emit('modalClose');
      this.onSubmitQuery();
    },

    onEnter(query) {
      this.searchQuery = query;
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.onSubmit();
      }
    },

    onSubmitQuery() {
      this.pushWelcomeSearchButtonClickAnalytics(
        this.selectedItemValue.city || this.preSelectedSearch.city,
        this.selectedItemValue.longLat || this.preSelectedSearch.longLat
      );
      this.pushHotJarAnalytics('welcome_location_search');
      if (
        this.selectedItemValue.longLat != null &&
        this.preSelectedSearch !== this.selectedItemValue.city
      ) {
        this.updateSearchQuery(this.$route.params, this.selectedItemValue);
        return;
      }
      this.updateSearchQuery(this.$route.params);
    },

    onClear() {
      this.selectedItemValue = null;
      this.preSelectedSearch = {
        city: null,
        longLat: null,
      };
    },

    onChange(args) {
      if (args) {
        const { text, city, longLat } = args;
        this.selectedItemValue = {
          text,
          city,
          longLat,
        };
      } else {
        this.selectedItemValue = {
          text: null,
        };
      }
    },

    updateSearchQuery(params, query) {
      const routeName =
        this.searchQuery === ''
          ? 'discoveryWithCity'
          : 'discoverySearchResults';

      let queryObject = {};
      queryObject.q = this.searchQuery;
      if (!!query) {
        queryObject.city = query.city;
        queryObject.lng = query.longLat[0];
        queryObject.lat = query.longLat[1];
      }

      if (!Boolean(this.selectedItemValue.city)) {
        queryObject.city = this.selectedItemValue.city;
        queryObject.lng = this.selectedContextFlight?.destination.longitude;
        queryObject.lat = this.selectedContextFlight?.destination.latitude;
        queryObject.q = this.searchQuery;
      }

      this.pushToRouterQuery(routeName, params, queryObject);
    },

    pushToRouterQuery(routeName, params, queryObject) {
      //Condition just to catch the rejection so chrome console doesn't complain
      if (
        JSON.stringify(queryObject) !== JSON.stringify(this.$route.query) &&
        queryObject.city
      ) {
        this.$router.push({
          name: routeName,
          params: params,
          query: queryObject,
        });
      }
    },

    onFormChange(value) {
      this.searchQuery = value;
    },

    async updateSelectedCity(city) {
      if (city) {
        if (this.$route.query.country) {
          city += ` ${this.$route.query.country}`;
        }
        const res = await mapboxService.getCities(city);
        if (res.features && res.features.length > 0) {
          this.preSelectedSearch = {
            city: res.features[0].place_name_en,
            longLat: res.features[0].center,
          };
          this.selectedItemValue = {
            ...this.preSelectedSearch,
            text: this.searchQuery || this.$route.query.q,
          };
        }
      }
    },
  },

  watch: {
    search(val) {
      if (!val || val === this.preSelectedSearch.city) {
        return;
      }
      this.searchCityDebounced(val);
    },

    reverseGeocodeResult(val, oldVal) {
      if (!!val && val !== oldVal) {
        for (let place of val.features) {
          if (place.place_type[0] == 'place') {
            this.updateSearchQuery(this.$route.params, {
              city: place.text,
              longLat: place.center,
            });
          }
        }
      }
    },
  },

  computed: {
    isWelcomePage() {
      return this.$route.name === 'welcome';
    },

    items() {
      if (!this.hasCitySearch || !this.search) {
        return [];
      } else {
        return this.citySearch.result.features.map((result) => ({
          city: result.text,
          longLat: result.center,
          text: result.place_name,
        }));
      }
    },

    reverseGeocodeResult() {
      return this.reverseGeocode.result;
    },

    preSelectedTextQuery() {
      return this.$route.query.q || '';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .search-input {
  background: white;
}

.v-input__slot .v-text-field__slot > input {
  font-size: 14px;
}

.search-box {
  border-radius: 8px;

  ::v-deep {
    .v-input__prepend-inner {
      .v-icon {
        color: var(--v-secondary-base) !important;
      }
    }
  }

  &-location {
    max-width: 350px;
    margin-right: 8px;

    @media (max-width: map-get($grid-breakpoints, md)) {
      width: 100%;
      max-width: unset;
      margin-right: 0;
      margin-bottom: 11px;
    }

    &-button {
      background-color: var(--v-secondary-base) !important;

      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 100% !important;
      }
    }

    &-wrapper {
      @media (max-width: map-get($grid-breakpoints, md)) {
        width: 100%;
        flex-direction: column;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: map-get($grid-breakpoints, md)) {
      flex-direction: column;
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    width: 350px;
  }
}

.search-button {
  width: 114px;
  @include font-size(16, 24, 600);
  border-radius: 8px;

  &-mobile {
    width: 100% !important;
  }
}

.is-fixed {
  @media (max-width: map-get($grid-breakpoints, md)) {
    position: absolute;
    top: 8px;
    right: 15px;
  }
}
</style>
