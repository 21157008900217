<template>
  <v-card
    class="rounded-lg my-10"
    min-height="100%"
    :style="{
      width: $vuetify.breakpoint.lgAndUp ? '100%' : null,
      border: $vuetify.breakpoint.mdAndDown ? 'none' : null,
    }"
    outlined
  >
    <v-row class="pa-0">
      <v-col
        :class="{ 'pa-10': $vuetify.breakpoint.lgAndUp }"
        :cols="$vuetify.breakpoint.lgAndUp ? 6 : 12"
        :order="$vuetify.breakpoint.lgAndUp && !imageFirst ? 2 : 0"
      >
        <v-img
          :alt="title"
          class="grey lighten-4 rounded-lg"
          :class="{ 'mx-4': $vuetify.breakpoint.lgAndUp }"
          :src="image"
          :height="$vuetify.breakpoint.smAndDown ? 200 : 313"
        />
      </v-col>
      <v-col
        :cols="$vuetify.breakpoint.lgAndUp ? 6 : 12"
        :class="{
          'pa-16': $vuetify.breakpoint.lgAndUp,
          'pt-1': $vuetify.breakpoint.mdAndDown,
        }"
      >
        <v-row>
          <v-col :class="{ 'pt-0': $vuetify.breakpoint.mdAndDown }">
            <h3 class="size20-weight400">
              {{ title }}
            </h3>
          </v-col>
        </v-row>

        <template v-if="description">
          <v-row no-gutters :class="{ 'my-1': $vuetify.breakpoint.mdAndDown }">
            <v-col>
              <p class="mb-0 size16-weight400">
                {{ description }}
              </p>
            </v-col>
          </v-row>
        </template>

        <template v-if="ctaText">
          <v-row>
            <v-col>
              <v-btn
                height="50"
                depressed
                color="primary"
                :href="ctaPath"
                target="_blank"
                large
                class="rounded-lg"
              >
                {{ ctaText }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import ConciergeHomeMixins from '@/modules/concierge/mixins/ConciergeHomeMixins';

export default {
  name: 'WelcomeCard',
  mixins: [ConciergeHomeMixins],
  props: {
    ctaText: {
      type: String,
      default: '',
    },
    ctaPath: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    imageFirst: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
